import React, { Suspense } from "react"

import { isSSR, actualCountry } from "../utils/constants"
import { colors } from "../utils/colors"
import Layout from "../components/layout"
import SEO from "../components/Seo"
import Spinner from "../components/Spinner"
import Do from "../components/TermsAndConditions/Do"
import Mx from "../components/TermsAndConditions/Mx"

const LegalTerms = React.lazy(() => import("../components/LegalTerms"))

export default () => {
  return (
    <>
      {!isSSR && (
        <Suspense fallback={<Spinner />}>
          <SEO
            title="Términos y condiciones"
            keywords={["Términos y condiciones, contratos, leyes"]}
            description="Términos y condiciones de Iban Online"
          />
          <Layout background={colors.GraySolitude}>
            <LegalTerms
              title="Condiciones generales de políticas del préstamo"
              sMSize="true"
            >
              {actualCountry === "do" ? <Do /> : <Mx />}
            </LegalTerms>
          </Layout>
        </Suspense>
      )}
    </>
  )
}
