import React from "react"

export default () => (
  <>
    <p>
      De una parte, <b>iBAN Online S.R.L.</b> , sociedad de responsabilidad
      limitada constituida en República Dominicana, con Registro Nacional de
      Contribuyentes núm. 131421725 y domicilio en la Av. Sarasota #62 Bella
      Vista Mall Local 42-A Primer nivel, Santo Domingo, República Dominicana,
      quien en lo adelante se denominará el Iban;
    </p>
    <p>De otra parte, [\], quien en lo adelante se denominará el “Deudor”.</p>
    <p>
      Atendido, a que Iban ha aceptado otorgarle un préstamo al Deudor según el
      procedimiento indicado en la página de Internet del Ibany sujeto a las
      condiciones establecidas en este Acuerdo.
    </p>
    <p>
      Atendido, a que este Acuerdo es el resultado de un proceso de negociación
      entre las Partes.
    </p>
    <p>Por lo tanto, el Deudor e Iban han acordado libremente lo siguiente:</p>

    <h4>
      <strong>Artículo I.</strong> Definiciones
    </h4>
    <p>
      “Fecha de Vencimiento”: día en que el Deudor debe pagar a Iban el Monto
      del Pago Total al Vencimiento.
    </p>

    <p>“Fecha de Pago”:día en el que el Deudor efectúa el pago a Iban.</p>
    <p>
      “Monto del Préstamo”: monto total principal del Préstamo otorgado al
      Deudor, el cual no incluye intereses ni ningún otro concepto.
    </p>
    <p>
      “Monto del Pago Total al Vencimiento”: monto total a ser pagado por el
      Deudor al Acreedor en relación con el Préstamo, el cual deberá incluir el
      Monto del Préstamo más todos los intereses, comisiones, cargos e impuestos
      asociados al Préstamo hasta la Fecha de Vencimiento.
    </p>
    <p>
      “Monto total de Saldo”: suma que debe ser pagada por el Deudor a Iban para
      saldar las obligaciones asumidas en este contrato, lo que incluye el Monto
      del Préstamo, más cualquier interés, cargos y gastos de cobro aplicables
      hasta la Fecha de Pago.
    </p>

    <h4>
      <strong>Artículo II.</strong> El Préstamo{" "}
    </h4>
    <p>
      2.1. El Préstamo. Iban prestará al Deudor el Monto del Préstamo aprobado,
      ascendente a [\], el cual será desembolsado en la cuenta bancaria del
      Deudor núm. [\].
    </p>

    <h4>
      <strong>Artículo III.</strong> Pago Del Préstamo Y Los Intereses
    </h4>
    <p>
      Sección 3.1. El Deudor se compromete a pagar en su totalidad a Iban a más
      tardar en la Fecha de Vencimiento el Monto del Pago Total al Vencimiento.
      Asimismo, el Deudor se compromete a pagar inmediatamente al Acreedor
      cualquier interés o concepto que se haya generado desde la Fecha de
      Vencimiento hasta la fecha de Pago, según se detalla en este Acuerdo.
      Sección.
    </p>
    <p>
      3.2. Intereses. (a) El Deudor deberá pagar a Iban, sin previo
      requerimiento, los intereses devengados por el Monto del Préstamo, por el
      período comprendido desde la fecha del desembolso inclusive, hasta la
      Fecha de Vencimiento.
    </p>
    <p>
      Sección 3.3. Pago. (a) El Pago de cualquier suma derivada de este Acuerdo
      siempre deberá hacerse mediante transferencias de fondos inmediatamente
      disponibles, sin deducción o compensación.
    </p>
    <p>
      Sección 3.4. Pago Anticipado. El Deudor podrá pagar el Préstamo antes de
      la Fecha de Vencimiento.
    </p>
    <p>
      Sección. 3.5. Pagos Parciales. El Deudor podrá hacer pagos parciales para
      abonar el préstamo.
    </p>
    <p>
      Sección. 3.6. El Deudor deberá identificar el pago anticipado o pago
      parcial con su Cédula de Identidad Electoral o número de su Acuerdo de
      préstamo y llamar al Centro de Servicio al Cliente de Iban para asegurar
      la aplicación de dicho pago al 829-547-0135. La aplicación del pago será
      realizada de acuerdo a lo estipulado en la Sección 3.3 de este documento.
      En caso de pago parcial o anticipado los intereses se calcularán en base
      al saldo insoluto del Monto del Préstamo posterior a la aplicación del
      pago parcial.
    </p>

    <h4>
      <strong>Artículo IV.</strong> Declaraciones Y Garantías
    </h4>
    <p>
      Sección 4.1. El Deudor declara y garantiza que antes de ejecutar este
      Acuerdo, el Acreedor, a través de su página web www.ibanonline.com.do, le
      facilitó toda la información necesaria en la medida prevista por la ley en
      relación con los términos y condiciones del Préstamo y las tarifas
      vinculadas.
    </p>

    <h4>
      <strong>Artículo V.</strong> Miscelánea
    </h4>
    <p>
      Sección 5.1. Autorizaciones. El Deudor reitera su autorización a Iban para
      solicitar, consultar, obtener y verificar, tantas veces como e Iban
      considere necesario cualquier información de cualquier récord financiero o
      crediticio de cualquier bureau de crédito, conforme la Declaración de
      Privacidad y Acceso a Información disponible en www.ibanonline.com.do, que
      fue leída y aceptada en su totalidad por el Deudor previamente.
    </p>
    <p>
      Sección 5.2. Modificaciones. El Deudor e Iban acuerdan que cualquier
      modificación de los términos y condiciones, como de las condiciones
      contractuales, las condiciones generales las condiciones específicas de
      los contratos de préstamos y el contrato de préstamo con garantía
      hipotecaria firmado por los el Deudor, serán aceptadas por parte del
      deudor tras los 30 días, de ser modificadas. Cualquier modificación se
      entiende como aceptada por el Deudor tras no recibir ninguna notificación
      por parte del Deudor en los 30 siguientes días de ser modificado. El
      Deudor, tendrá siempre disponible las condiciones particulares de su
      contrato actualizadas en www.ibanonline.com.do, y podrá solicitar por
      email dichas condiciones particulares. Si el cambio es desfavorable para
      usted, siempre intentaremos avisar con al menos 30 días de anticipación
      antes de realizar el cambio. Si quiere acceder a sus últimas
      modificaciones del contrato de préstamos con garantía hipotecaria puede
      solicitarlo haciendo click aquí. Puede cerrar su cuenta o notificarnos a
      través de la web si no desea aceptar el cambio, pero si nos notifica antes
      de los 30 días, se considerará que la ha aceptado.
    </p>
    <p>
      Sección 5.3. Vigencia Este Acuerdo entrará en vigencia desde la fecha de
      su aceptación y terminará cuando el Deudor cumpla con todas sus
      obligaciones asumidas en este.
    </p>
    <p>
      Sección 5.4. Legislación y jurisdicción aplicable. El presente Acuerdo se
      rige por las leyes de la República Dominicana. Cualquier conflicto que
      resulte sobre su interpretación y ejecución será conocido y juzgado por
      los tribunales de la República Dominicana.
    </p>
    <p>
      Sección 5.5. Para información, servicios, preguntas, reclamaciones y
      aclaraciones, el Deudor podrá contactar a nuestro departamento de servicio
      al cliente, al teléfono (829) 547-0135 o consultar la página de Internet
      del Acreedor (www.ibanonline.com.do). Los horarios de servicios son de
      9:00 AM a 6:00 PM, de lunes a viernes.
    </p>
    <p>
      Sección 5.6. Nulidad En caso de que un tribunal o autoridad administrativa
      de la jurisdicción competente declarase nula o inaplicable alguna de las
      disposiciones del presente Acuerdo, dicha nulidad o inaplicabilidad no
      afectará a las demás disposiciones del mismo, que continuarán en pleno
      vigor siempre que estas Condiciones y Términos Generales puedan subsistir
      sin las mismas. Asimismo, en caso de que se declarase de tal modo nula o
      inaplicable alguna de las disposiciones del presente Acuerdo, pero se
      restableciera su validez o aplicabilidad mediante la eliminación de parte
      de la disposición en cuestión, se aplicará ésta con la modificación
      pertinente para recuperar su validez y aplicabilidad.
    </p>
  </>
)
